<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      :title="dataInput.id ? 'Sửa văn bản pháp lý' : 'Thêm văn bản pháp lý'"
      @ok="accept($event)"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label>Số hiệu pháp lý <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="legalNumber"
              rules="required"
              :custom-messages="legalNumber"
            >
              <b-form-input
                v-model="dataInput.code"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Tên văn bản<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="legalName"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="fileName"
            rules="required"
            :custom-messages="legalFile"
          >
            <b-row>
              <b-col cols="8">
                <b-form-group>
                  <label>Tệp tin<span class="required">(*)</span></label>
                  <div class="d-flex align-content-center">
                    <b-form-input
                      v-model="fileName"
                      disabled
                      class="mr-1"
                    />
                    <input
                      ref="inputFile"
                      type="file"
                      hidden
                      @change="addFromFile"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                class="btn-down"
              >
                <b-button
                  variant="outline-primary"
                  @click="$refs.inputFile.click()"
                >
                  <feather-icon
                    icon="UploadIcon"
                    class="mr-50"
                  />
                  <span>Tải lên</span>
                </b-button>
              </b-col>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-row>
          </validation-provider>
          <b-form-group>
            <label>Trích yếu</label>
            <b-form-textarea
              v-model="dataInput.content"
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BFormTextarea, BRow, BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    dataInput: {
      type: Object,
      default: _ => ({}),
    },
  },
  data() {
    return {
      required,
      legalNumber: {
        required: 'Số hiệu pháp lý là bắt buộc',
      },
      legalName: {
        required: 'Tên văn bản là bắt buộc',
      },
      legalFile: {
        required: 'Tệp tin là bắt buộc',
      },
      fileName: null,
    }
  },
  methods: {
    // thêm tài liệu
    async addFromFile(event) {
      const tmpFiles = event.target.files
      const file = tmpFiles[0]
      this.fileName = file.name
    },
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          const formData = new FormData()
          const tmpFiles = this.$refs.inputFile.files
          const file = tmpFiles[0]
          formData.append('formFile', file)
          formData.append('code', this.dataInput.code)
          formData.append('name', this.dataInput.name)
          formData.append('content', this.dataInput.content)
          formData.append('filePath', this.dataInput.filePath)
          formData.append('fileName', this.dataInput.fileName)
          this.$emit('accept', formData)
        }
      })
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
